import React from "react";
import { graphql } from "gatsby";
import { Seo } from "../components/seo";
import { Layout } from "../components/layout/Layout";
import ReactMarkdown from "react-markdown";

export default function Page({ data, isPreview }) {
  const title = `Lysa fonder | ${
    data.strapi.lfPage.data.attributes.title || ""
  }`;
  return (
    <Layout isPreview={isPreview}>
      <Seo title={title.toString()} />
      <ReactMarkdown>
        {data.strapi.lfPage.data.attributes.content || ""}
      </ReactMarkdown>
    </Layout>
  );
}

export const query = graphql`
  query ($id: ID!) {
    strapi {
      lfPage(id: $id) {
        data {
          id
          attributes {
            title
            content
          }
        }
      }
    }
  }
`;
